<template>
    <div
        :style="{
            inset: 0 + 'px',
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            'flex-direction': 'column',
            height: '50%'
        }"
    >
        <div
            class="spinner-grow text-primary"
            role="status"
            :style="{ width: props.size ? props.size : '', height: props.size ? props.size : '' }"
        >
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>

<script setup lang="ts">
type Props = {
    size?: string;
};

const props = withDefaults(defineProps<Props>(), {
    size: undefined
});
</script>
